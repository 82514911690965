*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.test .MuiPaper-root {
    position: sticky;
}

.history .MuiAlert-message {
    width: 100%;
}

.search .MuiInputBase-input {
    font-size: 14px;
    padding: 8px 20px;
}
.search .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-radius: 20px;
}

.filter .MuiInputBase-input {
    padding: 15px 20px;
}

.price .MuiInputBase-input {
    padding: 4px;
    font-size: 14px;
}

.MuiStack-root .numberOf  {
    margin-left: auto;
    font-size: 12px;
}

.MuiBox-root .swiper {
    position: unset;
}

.swiper .swiper-button-next,
.swiper .swiper-button-prev {
    top: 200px;
}
.swiper .swiper-button-next {
    right: 10%;
}
.swiper .swiper-button-prev {
    left: 10%;
}

.swiper .swiper-button-next:after,
.swiper .swiper-button-prev:after {
    font-size: 15px;
}

.editor .ql-container {
    min-height: 300px;
}